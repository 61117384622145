<script lang="ts" setup>
import type { CmsSlot } from "@shopware-pwa/types";
import { pascalCase } from "scule";
import { getCmsLayoutConfiguration } from "@shopware-pwa/helpers-next";
import { resolveCmsComponent } from "@shopware-pwa/composables-next";

/**
 * IMPORTANT: For some CMS components we cannot use dynamic rendering since this is under the hood using AsyncComponent
 *
 * Issue: https://github.com/nuxt/nuxt/issues/26434
 *
 * So for now (temporarily) we do the following:
 *
 * 1. Copy & Paste all (block) cms components from original repo (packages/cms-base) into this repo - just for better overview
 * 2. Add all (block) cms components to the list of imports
 * 3. Comment in all (block) cms components that should be rendered dynamic
 * 4. Add all (block) cms components that should be statically renderd to the `staticComponents` array
 * 5. Add each (block) cms component to template section
 *
 * This ensures that the listed (and imported) components in `staticComponents` are registered and used statically with
 * the downside of bloating js independing of its usage. But on the other hand this is (IMO and for the moment) the only
 * working solution when using server components in our use case.
 *
 * Since the CMS components are registered globally (see nuxt.config.ts) they are either used from the NPM package
 * @shopware-pwa/cms-base or from within `components/global/cms/element/...`. For a better overview about whats used
 * from where all files from @shopware-pwa/cms-base cms components have been copied into this project having (partially)
 * empty template without functionality. Those empty components are getting dynamically rendered. So if we need them, we
 * have to lookup its content / functionality in the original sources.
 *
 */

// import CmsElementBuyBox from './element/CmsElementBuyBox.vue';
import CmsElementCategoryNavigation from './element/CmsElementCategoryNavigation.vue';
// import CmsElementCrossSelling from './element/CmsElementCrossSelling.vue';
// import CmsElementCustomForm from './element/CmsElementForm.vue';
// import CmsElementForm from './element/CmsElementForm.vue';
import CmsElementFormMaas from "./element/CmsElementFormMaas.vue";
import CmsElementIconTeaser from "./element/CmsElementIconTeaser.vue";
// import CmsElementImage from './element/CmsElementImage.vue';
import CmsElementImageGallery from "./element/CmsElementImageGallery.vue";
import CmsElementImageSlider from "./element/CmsElementImageSlider.vue";
import CmsElementIntro from "./element/CmsElementIntro.vue";
import CmsElementMaasTextImage from "./element/CmsElementMaasTextImage.vue";
// import CmsElementManufacturerLogo from './element/CmsElementManufacturerLogo.vue';
// import CmsElementProductBox from './element/CmsElementProductBox.vue';
// import CmsElementProductDescriptionReviews from './element/CmsElementProductDescriptionReviews.vue';
import CmsElementProductListing from "./element/CmsElementProductListing.vue";
import CmsElementProductlook from "./element/CmsElementProductlook.vue";
// import CmsElementProductName from './element/CmsElementProductName.vue';
import CmsElementProductSlider from "./element/CmsElementProductSlider.vue";
import CmsElementQuote from "./element/CmsElementQuote.vue";
import CmsElementSidebarFilter from "./element/CmsElementSidebarFilter.vue";
import CmsElementStageSlide from "./element/CmsElementStageSlide.vue";
import CmsElementStageSlider from "./element/CmsElementStageSlider.vue";
import CmsElementTeaserCategorySmall from "./element/CmsElementTeaserCategorySmall.vue";
import CmsElementTeaserCategoryWide from "./element/CmsElementTeaserCategoryWide.vue";
import CmsElementTeaserIcon from "./element/CmsElementTeaserIcon.vue";
import CmsElementTeaserInteractive from "./element/CmsElementTeaserInteractive.vue";
// import CmsElementText from './element/CmsElementText.vue';
// import CmsElementVimeoVideo from './element/CmsElementVimeoVideo.vue';
// import CmsElementYoutubeVideo from './element/CmsElementYoutubeVideo.vue';

// List of components using SharedCmsImage (since this is using SharedProxyImg as server component)
const staticComponents = [
  // CmsElementBuyBox,
  CmsElementCategoryNavigation,
  // CmsElementCrossSelling,
  // CmsElementCustomForm,
  // CmsElementForm,
  CmsElementFormMaas,
  CmsElementIconTeaser,
  // CmsElementImage,
  CmsElementImageGallery,
  CmsElementImageSlider,
  CmsElementIntro,
  CmsElementMaasTextImage,
  // CmsElementManufacturerLogo,
  // CmsElementProductBox,
  // CmsElementProductDescriptionReviews,
  CmsElementProductListing,
  CmsElementProductlook,
  // CmsElementProductName,
  CmsElementProductSlider,
  CmsElementQuote,
  CmsElementSidebarFilter,
  CmsElementStageSlide,
  CmsElementStageSlider,
  CmsElementTeaserCategorySmall,
  CmsElementTeaserCategoryWide,
  CmsElementTeaserIcon,
  CmsElementTeaserInteractive,
  // CmsElementText,
  // CmsElementVimeoVideo,
  // CmsElementYoutubeVideo,
];

/**
 * IMPORTANT: we removed dynamic renderer due to issues with server components and hydration
 *
 * Issue: https://github.com/nuxt/nuxt/issues/26434
 *
 * TODO: reenable dynamic renderer when issue is fixed
 *
 * see original: https://github.com/shopware/frontends/blob/main/packages/cms-base/components/public/cms/CmsPage.vue
 */

const props = defineProps<{
  content: CmsSlot;
}>();

const isStatic = (name: string = "") => {
  return staticComponents.map((x) => x.__name).includes(name);
};

/**
 * taken from https://github.com/shopware/frontends/blob/main/packages/composables/src/index.ts
 *
 * @see https://github.com/shopware/frontends/blob/782ef4d417dce6e6d60992bd54f876aa4bc5f45d/packages/composables/src/index.ts#L65
 */
const getName = () => {
  const componentName = props.content.type;
  const type =
    props.content.apiAlias === "cms_block" ? "Block" : props.content.apiAlias === "cms_section" ? "Section" : "Element";
  const componentNameToResolve = pascalCase(`Cms-${type}-${componentName}`);

  return componentNameToResolve;
};

const getStyleAndClassProperty = () => {
  const { cssClasses, layoutStyles } = getCmsLayoutConfiguration(props.content);

  return {
    style: layoutStyles,
    class: cssClasses,
  };
};

const p = getStyleAndClassProperty();

const DynamicRender = () => {
  const { resolvedComponent, isResolved } = resolveCmsComponent(props.content);
  if (resolvedComponent && isResolved) {
    return h(resolvedComponent, {
      content: props.content,
      style: p.style,
      class: p.class,
    });
  }
};
</script>

<template>
  <DynamicRender v-if="isStatic(getName()) === false" />

  <!--  This is also leading to hydration problems -->
  <!--  <template v-for="comp in staticComponents" :key="comp.__name">-->
  <!--    <component :is="getName()" :content="props.content" :class="p.class" :style="p.style" v-if="getName() === comp.__name"  />-->
  <!--  </template>-->

  <CmsElementCategoryNavigation
    v-else-if="getName() === 'CmsElementCategoryNavigation'"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsElementFormMaas
    v-else-if="getName() === 'CmsElementFormMaas'"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsElementIconTeaser
    v-else-if="getName() === 'CmsElementIconTeaser'"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsElementImageGallery
    v-else-if="getName() === 'CmsElementImageGallery'"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsElementImageSlider
    v-else-if="getName() === 'CmsElementImageSlider'"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsElementIntro
    v-else-if="getName() === 'CmsElementIntro'"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsElementMaasTextImage
    v-else-if="getName() === 'CmsElementMaasTextImage'"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsElementProductListing
    v-else-if="getName() === 'CmsElementProductListing'"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsElementProductlook
    v-else-if="getName() === 'CmsElementProductlook'"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsElementProductSlider
    v-else-if="getName() === 'CmsElementProductSlider'"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsElementQuote
    v-else-if="getName() === 'CmsElementQuote'"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsElementSidebarFilter
    v-else-if="getName() === 'CmsElementSidebarFilter'"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsElementStageSlide
    v-else-if="getName() === 'CmsElementStageSlide'"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsElementStageSlider
    v-else-if="getName() === 'CmsElementStageSlider'"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsElementTeaserCategorySmall
    v-else-if="getName() === 'CmsElementTeaserCategorySmall'"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsElementTeaserCategoryWide
    v-else-if="getName() === 'CmsElementTeaserCategoryWide'"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsElementTeaserIcon
    v-else-if="getName() === 'CmsElementTeaserIcon'"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsElementTeaserInteractive
    v-else-if="getName() === 'CmsElementTeaserInteractive'"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
</template>
